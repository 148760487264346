$(document).on('change', '.custom-file-input', (e) => {
    let text = '';
    for (let k in e.target.files) {
        if (e.target.files.hasOwnProperty(k)) {
            if (text.length === 0)
                text = e.target.files[k].name
            else
                text = text + ', ' + e.target.files[k].name
        }
    }

    if (text.length === 0)
        $(e.target).siblings('label').text('Choose file')
    else {
        $(e.target).siblings('label').text(text)
    }
});
