import Cropper from 'cropperjs';


let NCImageCropper = {};
window.NCImageCropper = NCImageCropper;

NCImageCropper.init = function (element = '.noardcode-image-cropper') {
    $(document).find(element).each(function () {
        let name = $(this).data('name');
        let disable = parseInt($(this).data('disable'));

        let first = true;

        let uploadInput = $(this).find('input[type="file"]').first();
        let settingsInput = $(this).find('input[name="crop_settings"]').first();
        let rotateInput = $(this).find('#' + name + '-rotate').first();
        let rotateReset = $(this).find('#' + name + '-rotateReset').first();

        let aspectChangeTime;
        let aspectChangeInterval = undefined;
        let aspectRatioX = $(this).find('input[name="' + name + '_width"]').first();
        let aspectRatioY = $(this).find('input[name="' + name + '_height"]').first();
        let aspectRatioPresetInput = $(this).find('select[name="aspect_ratio_preset"]');

        let unlock = $(this).find('#' + name + '-unlock').first();
        let lock = $(this).find('#' + name + '-lock').first();

        let imageViewer = $('#' + name + '-viewer');
        let imageCrop = $('#' + name + '-crop');
        let imageTitle = $('#' + name + '-title');

        let imageResizeWidth = imageViewer.data('width');
        let imageResizeHeight = imageViewer.data('height');

        let cropper;
        let cropperOptions = {
            aspectRatio: parseInt(imageResizeWidth) / parseInt(imageResizeHeight),
            viewMode: 1,
            dragMode: 'move',
            autoCropArea: true,
            autoCrop: true,
            restore: true,
            modal: false,
            guides: false,
            highlight: false,
            cropBoxMovable: false,
            cropBoxResizable: false,
            toggleDragModeOnDblclick: false,
            cropend(event) {
                cropImage(cropper)
            },
            zoom(event) {
                cropImage(cropper)
            },
            ready(event) {
                cropImage(cropper)
            }
        };

        lock.click(function () {
            lockCropper(1);
        });

        unlock.click(function () {
            lockCropper(0);
        });

        uploadInput.change(function () {
            first = false
            imageViewer.removeClass('show');
            imageTitle.val('');
            rotateInput.val(0);

            if (disable === 1) {
                lockCropper(0)
            }

            getImageInput(cropper, this);
            imageCrop.text(imageCrop.data('crop'));
        });

        cropper = new Cropper(imageViewer[0], cropperOptions);
        cropImage(cropper);

        rotateInput.change(function () {
            cropper.rotateTo(rotateInput.val());
            cropper.crop();
            cropImage(cropper);
        });

        rotateReset.click(function () {
            rotateInput.val(0);
            cropper.rotateTo(0);
            cropper.crop();
        });

        imageCrop.click(function () {
            if (cropper.length > 0)
                cropImage(cropper)
        });

        aspectRatioX.change(function () {
            setCropperAspectRatio(this.value, $(this).siblings('input').first().val())
        });
        aspectRatioY.change(function () {
            setCropperAspectRatio($(this).siblings('input').first().val(), this.value)
        });

        aspectRatioPresetInput.change(function () {
            let presetData = $(this).find('option:selected').data();
            aspectRatioX.val(presetData.width);
            aspectRatioY.val(presetData.height);
            setCropperAspectRatio(presetData.width, presetData.height);
            lockCropper(0);
        });

        function setCropperAspectRatio(width, height) {
            aspectChangeTime = new Date();

            if (aspectChangeInterval === undefined) {
                aspectChangeInterval = setInterval(() => {
                    if ((new Date().getTime() - aspectChangeTime.getTime()) > 250) {
                        sidePanels.getCurrentSidePanel().loader.open();
                        cropper.destroy();
                        cropperOptions.aspectRatio = parseInt(width) / parseInt(height);
                        cropper = new Cropper(imageViewer[0], cropperOptions);
                        clearInterval(aspectChangeInterval);
                        setTimeout(() => {
                            sidePanels.getCurrentSidePanel().loader.close();
                        });
                        aspectChangeInterval = undefined;
                        cropImage(cropper);
                    }
                }, 150);
            }
        }

        function lockCropper(lockDisable) {
            lock.toggle().toggleClass('show');
            unlock.toggle().toggleClass('show');

            if (lockDisable === 1) {
                disable = 1;
                cropper.disable();
                rotateInput.prop('disabled', true)
            } else {
                disable = 0;
                cropper.enable();

                rotateInput.prop('disabled', false)
            }
        };

        function cropImage(cropper) {
            imageCrop.text('Cropping...')
            let imageSettings = cropper.getData(true);

            imageSettings.resizeWidth = imageResizeWidth;
            imageSettings.resizeHeight = imageResizeHeight;
            imageSettings.name = imageTitle.val();

            settingsInput.val(JSON.stringify(imageSettings))
            imageCrop.text('Cropped')
        }

        function getImageInput(cropper, input) {
            if (input.files && input.files[0]) {
                let reader = new FileReader();

                reader.onload = function (e) {
                    cropper.replace(e.target.result);
                    imageTitle.val(input.files[0].name.replace(/.[^.]+$/, ''));

                    /** Throw on end of stack to fix large image first */
                    setTimeout(function () {
                        if (!imageViewer.is('visible'))
                            imageViewer.addClass('show');
                    }, 0);
                };
                reader.readAsDataURL(input.files[0]);
            }
        }
    });
}


