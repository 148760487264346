window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    window.sortable = require('jquery-ui/ui/widgets/sortable')
    require('bootstrap');
    window.moment = require('moment');
    let Chart = require('chart.js');
    require('bootstrap-datepicker');
    require('bootstrap-select');


} catch (e) {console.log(e)}

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
    $('.datepicker').datepicker({
        orientation: 'left bottom',
        forceParse: false,
        format: {
            /*
             * Say our UI should display a week ahead,
             * but textbox should store the actual date.
             * This is useful if we need UI to select local dates,
             * but store in UTC
             */
            toDisplay: function (date, format, language) {
                return moment(date).format('DD-MM-YYYY');
            },
            toValue: function (date, format, language) {
                return moment(date).format('YYYY-MM-DD');
            }
        }
    });
})
