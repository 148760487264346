let open = 0;
let sidebars = {};

/** Init sidebar */
sidebars.init = (_this) => {
    let sidebar = $(_this);
    sidebar.content = sidebar.find('.sidebar-body');
    sidebar.loaded = false;
    sidebar.refreshAlways = !!sidebar.data().reload ?? true;
    sidebar.route = sidebar.data().route;
    sidebar.close = (e) => {
        sidebar = sidebars.getCurrentSidebar();
        sidebar.trigger('hide.sidebar');
        sidebar.fadeOut(200);
        sidebar.forgetTarget(open);
        sidebar.trigger('hidden.sidebar');
        sidebar.content.find(['data-toggle^="#data-side"']).unbind();
        if (sidebar.refreshAlways === true) {
            sidebar.content.html('');
        }
    }
    sidebar.reload = () => {
        sidebar.loadURL(sidebar.url)
    };
    sidebar.loadURL = (url) => {
        setTimeout(function () {sidebar.loader.open()}, 20)
        loadUrl(sidebar, url)
        sidebar.loader.close()
    }
    registerMethods(sidebar);
    registerEvents(sidebar);
    registerLoader(sidebar);
}

sidebars.getSidebars = () => {
    let bars = []
    for (k in window.sidebars) {
        if (window.sidebars.hasOwnProperty(k) && typeof window.sidebars[k] === 'object') {
            bars[k] = window.sidebars[k];
        }
    }

    return bars;
}

sidebars.getCurrentSidebar = () => {
    return sidebars.getSidebars().reverse()[0]
}

/** Register all sidebars available in DOM */
$('[id^=data-sidebar-]').each(function (k, i) {
    sidebars.init(i);
});


function registerMethods(sidebar) {
    /** Targets */
    sidebar.registerTarget = (e) => {
        sidebar.target = $(e.target);
        let exists = false;
        let currentSidebars = sidebars.getSidebars()

        if (currentSidebars.length) {
            for (let k in currentSidebars) {
                if (exists === false && currentSidebars.hasOwnProperty(k)) {
                    if (currentSidebars[k][0].id === sidebar[0].id) {
                        exists = true;
                        break;
                    }
                }
            }
        }
        if (exists) {
            open--
            delete sidebars[open];
        }
        sidebars[open] = sidebar;
        window.sidebars = sidebars;
        open = sidebars.getSidebars().length
    }
    sidebar.forgetTarget = () => {
        delete sidebars[(open - 1)];
        window.sidebars = sidebars;
        open = sidebars.getSidebars().length
    }
}

function registerEvents(sidebar) {
    /** Open sidebar */
    let width;
    $(document).on('click', '[data-toggle="#' + sidebar[0].id + '"]', (e) => {
        let item = $(e.target)

        if (item.is('i')) {
            item = item.parent();
        }

        if (item.data().route !== undefined && item.data().route.length) {
            sidebar.route = item.data().route;
        }

        if (sidebar.loaded === false || sidebar.refreshAlways === true) {
            sidebar.loader.open();
            sidebar.trigger('show.sidebar');
            sidebar.url = sidebar.route;
            sidebar.content.load(sidebar.route, null, function () {
                sidebar.loader.close();
                sidebar.content.find('[id^=data-sidebar-]').each(function (k, i) {
                    sidebars.init(i);
                });
                registerLinks(sidebar);
                registerForm(sidebar);
                window.createSortableTables();
                sidebar.loaded = true;
                sidebar.trigger('shown.sidebar');
            })
        }

        sidebar.registerTarget(e)
        sidebar.fadeIn(200);
        if (width === undefined) {
            width = sidebar.width();
        }
        sidebar.width(width - (50 * open));
    });

    /** Close sidebar */
    sidebar.before().mousedown((e) => {
        e.target.id === sidebar[0].id ? sidebar.close() : '';
    });
    sidebar.find('.sidebar-close').click(() => {
        sidebar.close();
    });
}

function registerLinks(sidebar) {
    /** Follow links */
    sidebar.content.find('a').click((e) => {
        e.preventDefault();
        sidebar.loader.open();
        sidebar.trigger('reload.sidebar');
        if (!$(e.target).is('a')) {
            e.target = $(e.target).closest('a')[0];
        }

        loadUrl(sidebar, e.target.href);
    });
}

function loadUrl(sidebar, url) {
    sidebar.content.load(url, null, () => {
        registerLinks(sidebar);
        registerForm(sidebar);
        window.createSortableTables();
        sidebar.loader.close();
        sidebar.trigger('reloaded.sidebar');
        sidebar.url = url;
    });
}

function registerForm(sidebar) {
    /** Register form */
    sidebar.form = sidebar.content.find('form');

    /** Form handling */
    sidebar.form.each((k, form) => {
        $(form).submit((e) => {
            e.preventDefault();
            sidebar.loader.open();
            $.post({
                url: e.target.action,
                dataType: 'JSON',
                processData: false,
                contentType: false,
                data: $(form).getFormDataAssociative(),
                success: (result) => {
                    if ($(form).closest('[id^="data-sidebar-"]').is(sidebar)) {
                        sidebar.trigger('formSuccess.sidebar', [result]);
                    }

                    if (result.route !== undefined && result.route.length) {
                        sidebar.loadURL(result.route);
                    }
                },
                error: (error) => {
                    sidebar.trigger('formError.sidebar', error);
                    if (error.status === 422) {
                        sidebar.form.fillFormErrors(error.responseJSON.errors)
                    }
                },
                complete: () => {
                    sidebar.loader.close();
                }
            })
        })

    })
}

function registerLoader(sidebar) {
    sidebar.loader = sidebar.find('.sidebar-loader');

    /** Open */
    sidebar.loader.open = () => {
        sidebar.trigger('show.sidebar.loader');
        sidebar.loader.fadeIn(200);
        sidebar.trigger('shown.sidebar.loader');
    }

    /** Close */
    sidebar.loader.close = () => {
        sidebar.trigger('hide.sidebar.loader');
        sidebar.loader.fadeOut(150);
        sidebar.trigger('hidden.sidebar.loader');
    }
}
