jQuery.fn.swap = function (newIndex) {
    if (!Number.isInteger(newIndex) && !['up', 'down'].includes(newIndex)) {
        throw new Error('Incorrect index format! Allowed formats are: "up", "down" or an index of the sibling to swap with');
    }
    if (Number.isInteger(newIndex)) {
        this.insertBefore(this.siblings()[newIndex]);
    } else {
        if (newIndex === 'up') {
            this.insertBefore($(this.siblings()[this.index() - 1]));
        } else {
            this.insertAfter($(this.siblings()[this.index()]));
        }
    }
}
