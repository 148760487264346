import ClipboardJS from 'clipboard'

require('./uploadedFilename')
require('./cropper');

let standalone = false;
let mediaLibrary = $('#media-library-standalone');

if (mediaLibrary.length) {
    standalone = true;

    let sidePanel = $('#data-side-panel-media-library');
    mediaLibrary.url = mediaLibrary.data('url');

    if (sidePanel.length === 0) {
        throw new Error('No side panel is found, the functionality of the media library can\'t work without this dependency.')
    }

    mediaLibrary.loader = mediaLibrary.find('.loader')
    mediaLibrary.loader.open = () => {
        mediaLibrary.loader.fadeIn(200);
    }
    mediaLibrary.loader.close = () => {
        mediaLibrary.loader.fadeOut(150);
    }

    mediaLibrary.on('click', 'a[data-toggle^="#data-side-panel-media-library"]', function (e) {
        e.preventDefault();
        setTimeout(() => {
            sidePanels.getCurrentSidePanel().content.html('');
            sidePanels.getCurrentSidePanel().loadURL($(this).attr('href'));
        });
    });

    mediaLibrary.on('submit', '.noardcode-media-library-panel-input > form', function (e) {
        e.preventDefault();
        mediaLibrary.loader.open();
        $.post({
            url: e.target.action,
            dataType: 'JSON',
            processData: false,
            contentType: false,
            data: $(this).getFormDataAssociative(),
            success: (result) => {
                $(this).find('.custom-file-label').text($(this).find('.custom-file-label').data('text'))
                $(this).trigger('reset')
                mediaLibrary.loadContent(result.route ?? mediaLibrary.url)
            },
            error: (error) => {
                if (error.status === 422) {
                    sidePanel.form.fillFormErrors(error.responseJSON.errors)
                }
            },
            complete: () => {
                mediaLibrary.loader.close();
            }
        })
    })

    mediaLibrary.on('click', 'a[target!="_blank"]:not([data-toggle])', function (e) {
        e.preventDefault();
        mediaLibrary.loadContent(this.href);
    });

    mediaLibrary.loadContent = (url) => {
        mediaLibrary.find('.card-body > .alert').slideUp(250)
        setTimeout(() => {
            mediaLibrary.find('.card-body > .alert').remove();
        }, 250)
        mediaLibrary.loader.open();
        mediaLibrary.url = url;
        $.post({
            url: url,
            dataType: 'JSON',
            data: {
                _method: 'GET',
                standalone: true
            },
            success: (result) => {
                mediaLibrary.find('.media-library-items > .items-container').html(result.content)
                mediaLibrary.find('.media-library-breadcrumbs').html(result.breadcrumbs)
                mediaLibrary.find('form input[name="parent_id"]').val(
                    result.current_folder_id !== undefined && result.current_folder_id !== null
                        ? result.current_folder_id
                        : ''
                );
                mediaLibrary.find('.card-header .add-folder').each((k, i) => {
                    i.href = i.href.replace(/\/\d*$/, '');
                    i.href += result.current_folder_id !== undefined && result.current_folder_id !== null
                        ? '/' + result.current_folder_id
                        : '';
                });

            },
            error: (error) => {
                console.log(error)
            },
            complete: () => {
                mediaLibrary.loader.close();
            }
        })
    };
}

new ClipboardJS('.copy-to-clipboard');

$(document).on('nc.sidePanel.shown nc.sidePanel.reloaded', '[id^="data-side-panel-media"]', function () {
    let sidePanel = window.sidePanels.getCurrentSidePanel();

    /** Exit when side panel is closed */
    if (sidePanel === undefined) {
        return;
    }

    /** Initialize croppers */
    NCImageCropper.init();

    if (standalone) {
        sidePanel.content.find('.cancelForm').unbind('click').click((e) => {
            e.preventDefault();
            sidePanel.close();
        });
        return;
    }

    sidePanel.content.find('.media-item > table').click((e) => {
        let mediaItem = $(e.target).closest('.media-item');
        let inputGroup = $(sidePanel.target).closest('.input-group');
        let preview = inputGroup.closest('.form-group').find('.media-input-preview');

        inputGroup.find('input[type="file"]').val('');
        inputGroup.find('label').text(mediaItem.data('title'));
        inputGroup.find('input[type!="file"]').val(mediaItem.data('id'));
        preview.removeClass('d-none').find('img').attr('src', mediaItem.data('url'))
        sidePanel.close();
    });
});

$(document).on('nc.sidePanel.formSuccess', '[id^="data-side-panel-media"]', function (e, result) {
    let sidePanel = sidePanels.getCurrentSidePanel();

    if (standalone) {
        if (result.item.type === 'image' || result.item.type === 'video') {
            mediaLibrary.loadContent(mediaLibrary.url);
        } else if (result.item.type === 'folder') {
            mediaLibrary.loadContent(result.route);
        }

        setTimeout(() => {
            sidePanel.close();
        })
        return;
    }

    if (result.item !== undefined && (result.item.type === 'image' || result.item.type === 'video')) {
        let inputGroup = $(sidePanel.target).closest('.input-group');
        let preview = inputGroup.closest('.form-group').find('.media-input-preview');

        inputGroup.find('label').text(result.item.title);
        inputGroup.find('input[type!="file"]').val(result.item.id);
        preview.removeClass('d-none').find('img').attr('src', result.item.path_frontend);

        sidePanel.close(e);
    }
});

$(document).on('nc.sidePanel.formError', '[id^="data-side-panel-media"]', function (e, result) {
    if (result.responseJSON === undefined || result.responseJSON.message === undefined) {
        return;
    }

    let sidePanel = sidePanels.getCurrentSidePanel();

    if (sidePanel.content.find('.card-body > .alert').length > 0) {
        sidePanel.content.find('.card-body > .alert').parent().remove();
    }

    sidePanel.content.prepend(
        '<div class="card-body pb-0"><div class="alert alert-danger w-100 mb-0">' + result.responseJSON.message + '</div></div>'
    );
});
