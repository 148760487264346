$(document).on(
    'change',
    'input[type!="password"][data-session!="false"], ' +
    'select[data-session!="false"], ' +
    'textarea[data-session!="false"]', function () {
        $.post({url: '/form-session', dataType: 'JSON', data: {field: this.name, type: this.type, value: this.value}});
    });

$('.cancelForm').click(function (e) {
    e.preventDefault()
    $.get({url: '/form-session', success: () => {window.location.href = this.href;}})
})
