let overview = $('#nc-menu-overview')
let search = overview.find('[id$="-menu-search-input"]');
let searchExit = overview.find('.overview-search-exit');
let items = overview.find('.overview-item');
let itemsMetaData = [];

items.each(function () {
    const item = $(this);
    itemsMetaData[this.dataset.id] = item.find('.card-title').text() + ' ' + item.data('description');
});

search.keyup(function () {
    if(this.value.length) {
        if(searchExit.is(':hidden')) {
            searchExit.fadeIn()
        }
    } else {
        searchExit.fadeOut()
    }

    for (const key in itemsMetaData) {
        if (!itemsMetaData.hasOwnProperty(key)) {
            continue;
        }

        const data = itemsMetaData[key];
        const currentItem = items.filter(`[data-id="${key}"]`);

        if (data === undefined) {
            continue;
        }

        if (!data.match(new RegExp(this.value, 'gi'))) {
            currentItem.fadeOut();
        } else if (currentItem.is(':hidden')){
            currentItem.fadeIn()
        }
    }
});

searchExit.click(function () {
    search.val('');
    search.keyup();
})
