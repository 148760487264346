jQuery.fn.getFormDataAssociative = function () {
    let fd = new FormData();
    let f = this.serializeArray();
    for (let k in f) {
        if (f.hasOwnProperty(k)) {
            fd.append(f[k].name, f[k].value);
        }
    }

    this.find('input[type="file"]').each((k, v) => {
        for (let fk in v.files) {
            if (v.files.hasOwnProperty(fk))
                fd.append($(v).attr('name'), v.files[fk] ?? '');
        }
    });

    return fd;
}

jQuery.fn.fillFormErrors = function (err) {
    let f = $(this[0]);
    for (let k in err) {
        if (err.hasOwnProperty(k)) {
            f.find('[name="' + k + '"]').addClass('is-invalid')
                .closest('.form-group')
                .find('.invalid-feedback')
                .addClass('d-block')
                .text(err[k]);
        }
    }
}
