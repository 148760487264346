let open = 0;
let sidePanels = {};

if ($.fn.getFormDataAssociative === undefined || $.fn.fillFormErrors === undefined) {
    require('./jQuery.formData.extension');
}

/** Init sidebar */
sidePanels.init = (_this) => {
    let sidePanel = $(_this);
    sidePanel.content = sidePanel.find('.side-panel-body');
    sidePanel.loaded = false;
    sidePanel.refreshAlways = !!sidePanel.data().reload ?? true;
    sidePanel.route = sidePanel.data().route;
    sidePanel.close = (e) => {
        sidePanel = sidePanels.getCurrentSidePanel();
        sidePanel.trigger('nc.sidePanel.hide');
        sidePanel.fadeOut(200);
        sidePanel.forgetTarget(open);
        sidePanel.trigger('nc.sidePanel.hidden');
        sidePanel.content.find(['data-toggle^="#data-side"']).unbind();
        if (sidePanel.refreshAlways === true) {
            sidePanel.content.html('');
        }
    }
    sidePanel.reload = () => {
        sidePanel.loadURL(sidePanel.url)
    };
    sidePanel.loadURL = (url) => {
        loadUrl(sidePanel, url)
    }
    registerMethods(sidePanel);
    registerEvents(sidePanel);
    registerLoader(sidePanel);
}

sidePanels.getSidePanels = () => {
    let panels = []
    for (let k in window.sidePanels) {
        if (window.sidePanels.hasOwnProperty(k) && typeof window.sidePanels[k] === 'object') {
            panels[k] = window.sidePanels[k];
        }
    }

    return panels;
}

sidePanels.getCurrentSidePanel = () => {
    return window.sidePanels.getSidePanels().reverse()[0];
}

window.sidePanels = sidePanels;

/** Register all sidePanels available in DOM */
$('[id^=data-side-panel-]').each(function (k, i) {
    sidePanels.init(i);
});

function registerMethods(sidePanel) {
    /** Targets */
    sidePanel.registerTarget = (e) => {
        sidePanel.target = $(e.target);
        let exists = false;
        let currentSidePanels = sidePanels.getSidePanels();

        if (currentSidePanels.length) {
            for (let k in currentSidePanels) {
                if (exists === false && currentSidePanels.hasOwnProperty(k)) {
                    if (currentSidePanels[k][0].id === sidePanel[0].id) {
                        exists = true;
                        break;
                    }
                }
            }
        }

        if (exists) {
            open--
            delete sidePanels[open];
        }

        sidePanels[open] = sidePanel;
        window.sidePanels = sidePanels;
        open = sidePanels.getSidePanels().length
    }
    sidePanel.forgetTarget = () => {
        delete sidePanels[(open - 1)];
        window.sidePanels = sidePanels;
        open = sidePanels.getSidePanels().length
    }
}

function registerEvents(sidePanel) {
    let width;

    /** Open side panel */
    $(document).on('click', '[data-toggle="#' + sidePanel[0].id + '"]:not(.disabled)', (e) => {
        let item = $(e.target)

        if (item.is('i')) {
            item = item.parent();
        }

        if (item.data().route !== undefined && item.data().route.length) {
            sidePanel.route = item.data().route;
        }

        console.log(sidePanel.route.length);

        if (sidePanel.loaded === false || sidePanel.refreshAlways === true) {
            sidePanel.trigger('nc.sidePanel.show', sidePanel);
            if(sidePanel.route.length) {
                sidePanel.loader.open();
                sidePanel.url = sidePanel.route;
                sidePanel.content.load(sidePanel.route, null, function () {
                    sidePanel.loader.close();
                    registerLinks(sidePanel);
                    registerForm(sidePanel);
                    registerSortableTables();
                    sidePanel.loaded = true;
                    sidePanel.trigger('nc.sidePanel.shown');
                })
            } else {
                registerLinks(sidePanel);
                registerForm(sidePanel);
                registerSortableTables();
                sidePanel.loaded = true;
                sidePanel.trigger('nc.sidePanel.shown');
            }
        }

        sidePanel.registerTarget(e)
        sidePanel.fadeIn(200);
        if (width === undefined) {
            width = sidePanel.width();
        }

        if (window.sidebars !== undefined) {
            /** TODO: remove when sidebars are totally removed from pub */
            let openTotalPub = (open + window.sidebars.getSidebars().length);
            sidePanel.width(width - (50 * (openTotalPub)));
            sidePanel.css('z-index', 100 + openTotalPub);
        } else {
            sidePanel.width(width - (50 * open));
        }
    });

    /** Close sidebar */
    sidePanel.before().mousedown((e) => {
        e.target.id === sidePanel[0].id ? sidePanel.close() : '';
    });
    sidePanel.find('.sidebar-close').click(() => {
        sidePanel.close();
    });
}

function registerLinks(sidePanel) {
    /** Follow links */
    sidePanel.content.find('a[target!="_blank"]').click((e) => {
        e.preventDefault();
        sidePanel.loader.open();
        sidePanel.trigger('nc.sidePanel.reload');
        if (!$(e.target).is('a')) {
            e.target = $(e.target).closest('a')[0];
        }
        loadUrl(sidePanel, e.target.href);
    });
}

function loadUrl(sidePanel, url) {
    sidePanel.content.load(url, null, () => {
        registerLinks(sidePanel);
        registerForm(sidePanel);
        registerSortableTables();
        sidePanel.content.find('[id^=data-side-panel-]').each(function (k, i) {
            sidePanels.init(i);
        });
        sidePanel.loader.close();
        sidePanel.trigger('nc.sidePanel.reloaded');
        sidePanel.url = url;
    });
}

function registerForm(sidePanel) {
    /** Register form */
    sidePanel.form = sidePanel.content.find('form:not(.disabled)');

    /** Form handling */
    sidePanel.form.each((k, form) => {
        $(form).submit((e) => {
            e.preventDefault();
            sidePanel.loader.open();
            $.post({
                url: e.target.action,
                dataType: 'JSON',
                processData: false,
                contentType: false,
                data: $(form).getFormDataAssociative(),
                success: (result) => {
                    if ($(form).closest('[id^="data-side-panel-"]').is(sidePanel)) {
                        sidePanel.trigger('nc.sidePanel.formSuccess', [result]);
                    }

                    if (result.route !== undefined && result.route.length) {
                        sidePanel.loadURL(result.route);
                    }

                    if (result.routeReloadPage !== undefined && result.routeReloadPage.length) {
                        window.location.href = result.routeReloadPage;
                    }
                },
                error: (error) => {
                    sidePanel.trigger('nc.sidePanel.formError', error);
                    if (error.status === 422) {
                        sidePanel.form.fillFormErrors(error.responseJSON.errors)
                    }
                },
                complete: () => {
                    sidePanel.loader.close();
                }
            })
        })
    })
}

function registerLoader(sidePanel) {
    sidePanel.loader = sidePanel.find('.sidebar-loader');

    /** Open */
    sidePanel.loader.open = () => {
        sidePanel.trigger('nc.sidePanel.loader.show');
        sidePanel.loader.fadeIn(200);
        sidePanel.trigger('nc.sidePanel.loader.shown');
    }

    /** Close */
    sidePanel.loader.close = () => {
        sidePanel.trigger('nc.sidePanel.loader.hide');
        sidePanel.loader.fadeOut(150);
        sidePanel.trigger('nc.sidePanel.loader.hidden');
    }
}

function registerSortableTables() {
    if (window.hasOwnProperty('createSortableTables')) {
        window.createSortableTables();
    }
}
