window.createSortableTables = () => {
    $('.sortable-table').each(function (k, v) {
        let itemsTableWrapper = $(this);
        let itemsTable = itemsTableWrapper.find('table');

        if (itemsTable.length) {
            let tableBody = itemsTable.find('tbody');
            tableBody.sortable({
                stop: function () {
                    itemsTableWrapper.trigger('sorting.sortableTable')
                    let ids = [];
                    let data = {};

                    tableBody.find('tr').each((k, i) => {
                        ids[k] = $(i).data('primary-key');
                    });

                    // if(itemsTableWrapper.data('sortable-form-data').length) {
                    //     data = itemsTableWrapper.data('sortable-form-data')
                    // }

                    data.ids = ids;

                    $.post({
                        url: itemsTableWrapper.data('sortable-route'),
                        dataType: 'JSON',
                        data: data,
                        success: function (result) {
                            itemsTableWrapper.trigger('success.sortableTable', result)
                        }
                    })
                }
            });
        }
    });
}
window.createSortableTables();
