/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');
// import autosize from 'autosize';

$(document).ready(function () {
    /**
     * Plugins
     * */
    require('./plugins/sidebar')
    require('./plugins/formSessions')
    require('./plugins/sortableTable')
    require('./plugins/fileUploadNameShow');
    require('./plugins/jQuery/jQuery.formDataExtension')
    require('./plugins/jQuery/jQuery.swapSiblings')

    /**
     * Components
     * */
    require('./components/menu');

    /**
     * Modules
     **/
});
